#pdf-download {
    display: none;
}

#pdf-controls {
    justify-content: space-between;
}

#msdoc-iframe {
    overflow-x: hidden;
    height: 105%;
}