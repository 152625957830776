.headerLink {
    text-decoration: none;
    color: inherit;
    text-transform: none !important;
}

.headerLogo {
    max-width: 80px;
    margin-right: 1em;
}

.menuButtonLight {
    background-color: rgb(255, 255, 255) !important;
}

.menuButtonLight:hover {
    background-color: rgba(255, 255, 255, 0.84) !important;
}

.menuButtonDark:hover {
    background-color: linear-gradient(rgba(255, 255, 255, 0.19), rgba(255, 255, 255, 0.19)) !important;
}
