.player {
    background-color: #333;
}
.controls {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}
.controls:hover {
    opacity: 1;
}
.controls:not(:hover) {
    transition-delay: 0.5s;
}
