.video-content {
    max-height: 65vh;
    width: 100%;
}

.video-element {
    width: 100%;
}

.video-element-fullscreen {
    min-width: 100%;
    min-height: 100%;
}

.MuiSlider-track {
    transition: none !important;
}

.MuiSlider-thumb {
    transition: none !important;
}

.player {
    background-color: #333;
}
.controls {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}
.controls:hover {
    opacity: 1;
}
.controls:not(:hover) {
    transition-delay: 0.5s;
}

/* Media Queries for mobile scaling */

@media (width <= 800px) {
    h5 {
        font-size: 1rem !important;
    }

    .navigation-button {
        width: 49%;
    }
}